import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/FullWidthWithImage.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground"
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Testimonials from "components/testimonials/TwoColumnWithImage";
import ContactUs from "components/forms/SimpleContactUs";


const style = {
    marginTop: '25px' /* Adjust the value as needed */
};

export default () => (
  <AnimationRevealPage>
    <Hero />
    <section id="details">
    <SliderCard />
    </section>
    {/* <TrendingCard /> */}
    {/* <MainFeature /> */}
    {/* <Blog /> */}
    {/* <Testimonial textOnLeft={true}/> */}
    <section id="sellyourhome">
    <ContactUs />
    </section>
    {/* <Features /> */}
    <section id="aboutme">
    <Testimonials />
    </section>
    {/* <FAQ /> */}
    {/* <SubscribeNewsLetterForm /> */}
    <Footer />
  </AnimationRevealPage>
);
