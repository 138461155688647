import axios from 'axios';

const COINGECKO_API_URL = 'https://api.coingecko.com/api/v3/simple/price';

export const getBitcoinPriceInCAD = async () => {
  try {
    const response = await axios.get(COINGECKO_API_URL, {
      params: {
        ids: 'bitcoin',
        vs_currencies: 'cad',
      },
    });
    return response.data.bitcoin.cad;
  } catch (error) {
    console.error('Error fetching Bitcoin price:', error);
    return null;
  }
};
