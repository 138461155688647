import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import ReactModalAdapter from "helpers/ReactModalAdapter";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import front1Image from "../../images/properties/milton/front1.jpg";
import front2Image from "../../images/properties/milton/front2.jpg";
import front3Image from "../../images/properties/milton/front3.jpg";
import backyard1Image from "../../images/properties/milton/backyard1.jpg";
import backyard2Image from "../../images/properties/milton/backyard2.jpg";
import entranceImage from "../../images/properties/milton/entrance.jpg";
import stairwellImage from "../../images/properties/milton/stairwell.jpg";
import livingImage from "../../images/properties/milton/livingroom.jpg";
import diningImage from "../../images/properties/milton/diningroom.jpg";
import kitSittingImage from "../../images/properties/milton/kitchenSittingRoom.jpg";
import kitchen1Image from "../../images/properties/milton/kitchen1.jpg";
import kitchen2Image from "../../images/properties/milton/kitchen2.jpg";
// import kitchen3Image from "../../images/properties/milton/kitchen3.jpg";
import primary11Image from "../../images/properties/milton/primary1.jpg";
// import primary2Image from "../../images/properties/milton/primary2.jpg";
// import primaryWashroomImage from "../../images/properties/milton/primary_washroom.jpg";
import bedroom2Image from "../../images/properties/milton/bedroom2.jpg";
import bedroom3Image from "../../images/properties/milton/bedroom3.jpg";
import bedroom4Image from "../../images/properties/milton/bedroom4.jpg";
import spaImage from "../../images/properties/milton/salon_spa.jpg";
// import { images } from '../../images';
 

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const PositionControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;
const CardSlider = styled(Slider)`
  ${tw`mt-8`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl cursor-pointer`,
  `height: 500px !important;`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;

const FullImageModal = ({ isOpen, onRequestClose, imageSrc }) => (
  <ReactModalAdapter
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    // style={{
    //   content: {
    //     top: '50%',
    //     left: '50%',
    //     right: 'auto',
    //     bottom: 'auto%',
    //     marginRight: '-50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: '80%',
    //     height: '80%',
    //     overflow: 'hidden',
    //     padding: '0',
    //     position: 'relative'
    //   },
    //   overlay: {
    //     padding:'20px',
    //     position: 'fixed',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
        

    //   },
    // }}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Dark background with transparency
        zIndex: 1000, // Ensure the overlay is above other elements
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '100vh',
        padding: '0',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20%'
      },
    }}



  >
    <div tw="flex justify-end p-2">
      <button onClick={onRequestClose} tw="text-xl font-bold">&times;</button>
    </div>
    <div tw="w-full flex justify-center items-center p-4">
      <img src={imageSrc} alt="Full" tw="max-w-full max-h-[60vh] object-contain" />
    </div>
  </ReactModalAdapter>
);


const NavButton = styled.button`
  ${tw`mx-2 px-2 py-1 my-2 border rounded bg-primary-500`}
  background-color: ${(props) => (props.active ? tw`bg-primary-500` : tw`bg-gray-300` )}; //default background
  color: ${(props) => (props.active ? "#ffffff" : "#000000")}; 
  &:hover {
    ${tw`bg-primary-500 text-white`} //
  }
`;


export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');

  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      
      imageSrc: front3Image,
      title: "Front",
      navtitle: "Front",
      description: "The home features an elegant facade with beautiful brickwork, large windows that allow ample natural light, and a charming front patio. The recently installed new roof adds to the overall aesthetic appeal.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: front2Image,
      title: "Front Garage",
      navtitle: "",
      description: "A spacious driveway and a garage capable of accommodating up to four cars, providing ample parking and storage space.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: front1Image,
      title: "Front Patio",
      navtitle: "",
      description: "The home features a beautiful front patio, adorned with lush greenery and a vibrant garden, offering a seamless path to the backyard.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: backyard1Image,
      title: "Large Backyard",
      navtitle: "Large Backyard",
      description: "This beautiful and exclusive pie-shaped lot offers ample space for a pool, jacuzzi, or a large deck, providing endless possibilities for outdoor enjoyment.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: backyard2Image,
      title: "Private, Organic",
      navtitle: "Large Lot Size",
      description: "The large space beneath the gazebo is perfect for entertaining guests, situated in an organic yard that has never been exposed to pesticides or harmful chemicals.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: entranceImage,
      title: "Entrance",
      navtitle: "Entrance",
      description: "The elegant entrance provides direct and spacious access to a guest washroom, a full-size closet, and convenient access to the garage.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: stairwellImage,
      title: "Hardwood Stairwell",
      navtitle: "",
      description: "The beautiful and open two-level stairwell, crafted entirely from hardwood including the railing, offers access to both the second floor and the basement.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: primary11Image,
      title: "Primary Bedroom",
      navtitle: "Primary Bedroom",
      description: "The primary bedroom is spacious, featuring a large walk-in closet and an en-suite bathroom for added convenience and luxury.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: bedroom2Image,
      title: "Bedroom #2",
      navtitle: "Bedrooms",
      description: "The second bedroom is well-sized, boasting a large window and a spacious closet.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: bedroom3Image,
      title: "Bedroom #3",
      navtitle: "",
      description: "The generously sized bedroom located above the garage features a large window that floods the room with natural light.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: bedroom4Image,
      title: "Bedroom #4 / Office",
      navtitle: "",
      description: "The fourth bedroom is versatile, easily serving as either a comfortable bedroom or a functional office space.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: livingImage,
      title: "Living Room",
      navtitle: "Living Room",
      description: "The open-concept living room features hardwood flooring, large windows, and a spacious layout, creating an inviting and airy atmosphere.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: diningImage,
      title: "Dining Room",
      navtitle: "Dining",
      description: "The dining room, adorned with hardwood flooring and a beautiful chandelier, offers windowed access to the kitchen, making it a pleasure to cater to family and guests.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: kitchen1Image,
      title: "Kitchen",
      navtitle: "Kitchen",
      description: "The spacious kitchen features ample granite countertops, a large two-door stainless steel fridge, a sleek gas stove, a stainless steel dishwasher, and double sliding doors that open to the large pie-shaped backyard.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: kitchen2Image,
      title: "Kitchen",
      navtitle: "",
      description: "The kitchen boasts tall ceilings adorned with beautiful pot lights, ample preparation space, and a center island that accommodates at least three stools, creating a bright and functional area for cooking and gathering.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: kitSittingImage,
      title: "Breakfast Area",
      navtitle: "Breakfast",
      description: "The large breakfast area features expansive space and large windows throughout, providing a bright and welcoming atmosphere for morning meals.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },
    {
      
      imageSrc: spaImage,
      title: "Salon/Spa",
      navtitle: "Salon",
      description: "The salon, complete with a full washing station, a stylist station, and ample space for shelving and styling supplies, offers excellent investment potential for aspiring beauty entrepreneurs.",
      //sizeText: "Rome, Italy",
      //pricingText: "USD 39/Day",
      //rating: "4.8",
    },


    
  ];

  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  const goToSlide = (index) => {
    sliderRef.slickGoTo(index);

  };

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Details</Heading>
        
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <div tw="w-full flex justify-center mt-4">
          {cards.map((card, index) => (
            (index !==1 && index !==2 && index !==6 && index !==9 && index !==10 && index !==14) && ( 
            <NavButton key={index} onClick={() => goToSlide(index)} tw="mx-2 px-4 py-2 border rounded">
              {card.navtitle}
            </NavButton>
            )
          ))}
        </div>
        
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} onClick={() => openModal(card.imageSrc)} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
                </TitleReviewContainer>
                {/* <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.sizeText}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText>
                </SecondaryInfoContainer> */}
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton onClick={() => openModal(card.imageSrc)}>See Full Image</PrimaryButton>
            </Card>
          ))}
        </CardSlider>
      </Content>
      <FullImageModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        imageSrc={modalImageSrc}
      />
    </Container>
  );
};
