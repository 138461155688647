import React, { useState, useEffect } from 'react';
import { getBitcoinPriceInCAD } from '../services/cryptoService';

const ProductPrice = ({ productPriceInCAD }) => {
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  const [productPriceInBTC, setProductPriceInBTC] = useState(null);

  useEffect(() => {
    const fetchBitcoinPrice = async () => {
      const price = await getBitcoinPriceInCAD();
      setBitcoinPrice(price);
      if (price) {
        setProductPriceInBTC(productPriceInCAD / price);
      }
    };

    fetchBitcoinPrice();
  }, [productPriceInCAD]);
  const formatPrice = (price) => {
    return price.toLocaleString( { style: 'currency', currency: 'CAD' });
  };

  const formatBTC = (price) => {
    return price.toLocaleString('en-US', { maximumFractionDigits: 8 }) + ' BTC';
  };

  return (
    <div>
      {/* <h2>Product Price</h2> */}
      <h1 className="text-xl text-gray-800">${formatPrice(productPriceInCAD)} CAD </h1>
      {productPriceInBTC ? (
        <h1 style={{ color: '#FF9900'}}>{formatBTC(productPriceInBTC.toFixed(2))}</h1>
      ) : (
        <p>Loading Bitcoin price...</p>
      )}
    </div>
  );
};

export default ProductPrice;
