import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line;
import localImage from '../../../src/images/Front.png';
import ProductPrice from "components/ProductPrice";


import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6
`;


const Container = tw.div`relative -mx-8 -mt-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
const LeftColumn = tw.div`flex justify-end flex-col ml-0 mr-4 pl-10`;
// ml-1 sets the left margin to 5px and flexes basked on resized screens
const RightColumn = styled.div`
  background-image: url(${localImage});
  ${tw`bg-cover bg-center xl:ml-1 h-144 md:h-144 xl:h-200 lg:h-200 lg:w-1/2 lg:flex-1 relative`}
`;

const Overlay = styled.div`
  ${tw`absolute bottom-0 left-0 w-full bg-black bg-opacity-50`}
  height: 120px; // Adjust the height as needed
`;

const OverlayText = styled.div`
  ${tw`flex justify-center items-center text-white text-3xl`}
  height: 100%;
  padding: 0 20px;
`;



const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-tight`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-normal`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

const handleNavClick = (e, sectionId) => {
  e.preventDefault();
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

const productPriceInCAD = 1399000; // Example product price in CAD

export default ({
  navLinks = [
    <NavLinks key={1}>
      <NavLink href="#details" onclick={(e) => handleNavClick(e, 'details')}>Property Details</NavLink>
      <NavLink href="#sellyourhome" onclick={(e) => handleNavClick(e, 'sellyourhome')}>Sell Your Home</NavLink>
      <NavLink href="#aboutme" onClick={(e) => handleNavClick(e, 'aboutme')}>About Me</NavLink>
      {/* <NavLink href="#">Details</NavLink> */}
    </NavLinks>
  ],
  heading = (
    <>
      Featured Property
      <wbr />
      <br />
      <span tw="text-primary-500">954 Penson Crescent</span>
    </>
  ),
  description = "Welcome to your new home in Milton, Ontario, featuring a large pie-shaped lot, stunning curb appeal, a spacious open-concept layout, and a landscaped exterior perfect for families. This beautiful 4-bedroom home also boasts a recently upgraded roof and an upgradable basement with all the necessary rough-ins, making it perfect for a second apartment.",
  primaryActionUrl = "tel:+16134046112",
  primaryActionText = "Call",
  secondaryActionUrl = "mailto:mostafakhader@gmail.com",
  secondaryActionText = "Email"
}) => {

 
  return (
    <Container>
      <TwoColumn>
      <RightColumn>
        <Overlay>
          <OverlayText>
          <ProductPrice productPriceInCAD={productPriceInCAD} />
          </OverlayText>
        </Overlay>
      </RightColumn>
      <LeftColumn>
          {/* the collapsedBreakPointClass determines what type of nav is displayed, this can be found in the light.js file */}
        <StyledHeader links={navLinks} collapseBreakpointClass="lg" />
        <Content>
          <Heading>{heading}</Heading>
          <Paragraph>{description}</Paragraph>
          <Actions>
            <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
            </a>
            <a href={secondaryActionUrl} className="action secondaryAction">
                {secondaryActionText}
            </a>
          </Actions>
        </Content>
      </LeftColumn>
      </TwoColumn>
    </Container>
  );
};
