// src/helpers/ReactModalAdapte
import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import tw from 'twin.macro';

// Apply your custom styles here
const CustomModalStyles = styled.div`
  ${tw`flex justify-center items-center p-4`}
  /* Additional custom styles */
`;

const ReactModalAdapter = ({ isOpen, onRequestClose, children, style, ...rest }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        ...style?.content,
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        ...style?.overlay,
      },
    }}
    {...rest}
  >
    <CustomModalStyles>
      {children}
    </CustomModalStyles>
  </Modal>
);

export default ReactModalAdapter;
